import React from "react";
import "./style.scss";
import { formatDateString } from "../../utils/utlls";

export default function PodcastPage({ data }) {
  return (
    <div className="podcast">
      <div className="podcast__container">
        <video src={data.video_link} controls />
        <h1>{data.title}</h1>
        <br />
        <div>
          <strong>{formatDateString(data.date)}</strong>
        </div>
        <br />

        <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
      </div>
    </div>
  );
}
