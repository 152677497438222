import React from "react";
import PodcastPage from "../../../components/PodcastPage";
import podcastData from "../../../data/podcast";
import Layout from "../../../components/Layout";

export default function Mahasamadhi2024PodcastPage() {
  const link = "/podcasts/maha-samadhi-divas-2024";

  return (
    <Layout>
      <PodcastPage
        data={podcastData.filter((item) => link.includes(item.link))[0]}
      />
    </Layout>
  );
}
